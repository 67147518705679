<template>
  <div class="app-content">
    <el-card class="box-card" v-loading="loading">
      <div class="clearfix">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" @submit.native.prevent>
              <el-form-item label="企业名称">
                <el-input @change="onQuerySubmit" v-model="query.name" placeholder="输入企业名称"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerySubmit">查询</el-button>
                <el-button type="primary" @click="customCreate" v-if="permission.apiEnterpriseCreate">添加企业信息</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div class="block">
        <el-row>
          <el-col :span="24">
            <el-table :data="tableData" stripe style="width: 100%">
              <el-table-column prop="name" label="企业名称" min-width="180" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="charge" label="负责人" min-width="100">
              </el-table-column>
              <el-table-column prop="charge_phone" label="电话号码" min-width="180">
              </el-table-column>
              <el-table-column label="企业等级" min-width="100">
                <template slot-scope="scope">
                  {{scope.row.level === 1 ? '普通会员' : 'vip会员'}}
                </template>
              </el-table-column>
              <el-table-column prop="province" label="所属省份" min-width="180">
              </el-table-column>
              <el-table-column prop="city" label="所属城市" min-width="100">
              </el-table-column>
              <el-table-column prop="district" label="所属片区" min-width="180">
              </el-table-column>
              <el-table-column prop="address" label="详细地址" min-width="280" show-overflow-tooltip>
              </el-table-column>
              <el-table-column fixed="right" label="操作" min-width="100">
                <template slot-scope="scope">
                  <el-button @click="customDatail(scope.row)" type="text" size="small">详情</el-button>
                  <el-button type="text" size="small" @click="deleteItem(scope.row)" v-if="permission.apiEnterpriseDelete">注销</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" background :page-size="10" @current-change="changeCurrent" :current-page="query.page" :total="total"></el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { apiEnterpriseList, apiEnterpriseDelete } from "src/api/index";
import md5 from 'js-md5';
export default {
  components: {},
  data() {
    return {
      loading: false,
      query: {
        name: "",
        page: 1,
        logotype: 'basicEnterprise'
      },
      tableData: [],
      total: 10,
    };
  },
  mounted() {
    const _this = this;
    let query = JSON.parse(_this.$localStorage.get(md5('query')))
    if (query === null) {
      _this.query = _this.query
    } else if (query.logotype === 'basicEnterprise') {
      _this.query =  query;
    } else {
      _this.$localStorage.remove(md5("query"));
    }
    _this.changeCurrent(_this.query.page);
    _this.pageInit(_this).then(() => {});
  },
  computed: mapState({
    url: (state) => state.url,
    permission: (state) => state.permission,
  }),
  watch: {
    '$route': 'getEnterpriseData'
  },
  methods: {
    ...mapActions(["pageInit"]),
    //查询企业信息
    onQuerySubmit() {
      this.query.page = 1;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&name=' + this.query.name;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getEnterpriseData();
      } else {       
        this.$router.replace(route);
      }
    },
    //获取企业列表数据
    getEnterpriseData() {
      const _this = this;
      _this.loading = true;
      apiEnterpriseList(_this.query)
      .then((lists) => {
        _this.loading = false;
        _this.tableData = lists.data;
        _this.total = lists.total;
      })
      .catch((err) => {
        _this.error(err);
      });
    },
    //跳转添加企业信息页
    customCreate() {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push("/admin/basic/enterprise-add");
    },
    //跳转企业信息详情页
    customDatail(item) {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push("/admin/basic/enterprise-detail/" + item.id);
    },
    //删除企业信息
    deleteItem(item) {
      const _this = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        _this.loading = true;
        _this.pageInit(_this).then(() => {
          apiEnterpriseDelete({ id: item.id })
            .then((res) => {
              _this.loading = false;
              _this.$message({
                message: "注销成功",
                type: "success",
              });
              _this.onQuerySubmit();
            })
            .catch((err) => {
              _this.error(err);
            });
        });
      })
    },
    //切换分页
    changeCurrent(item) {
      this.query.page = item;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&name=' + this.query.name;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getEnterpriseData();
      } else {       
        this.$router.replace(route);
      }
    },
    //错误提示
    error(err) {
      this.loading = false;
      if (err.message === '没有操作权限') {
        return
      } else {         
        this.$message.error(err.message);
      }
    }
  },
};
</script>
<style lang="less" scoped>
.app-content {
  background-color: #ffffff;
}
.el-pagination {
  text-align: center;
  margin-top: 27px;
}
.el-avatar--circle {
  position: relative;
  margin-top: 7px;
}
</style>